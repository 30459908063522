var tour = {
	id: "hello-hopscotch",
	steps: [
		{
			title: "Screen selector",
			content: "Here you can choose your screens.",
			target: "tour-screen-selector",
			placement: "right",
			xOffset: 20,
			yOffset: -15
		},
		{
			title: "Inputs",
			content: "<ul class='in-tour'>" +
			"<li><strong>Product type</strong><br/>Choose product type you want to use</li>" +
			"<li><strong>Units</strong><br/>Choose unit you want to use</li>" +
			"<li><strong>Ratio</strong><br/>Choose ratio for current screen.</li>" +
			"<li><strong>Screen size</strong><br/>Choose width and height of current screen in units selected above. Width and/or height will be automatically calculated based on ratio selected above.</li>" +
			"</ul>",
			target: "tour-product-type",
			placement: "right",
			xOffset: 20,
			yOffset: 15
		},
		{
			title: "Share actions",
			content: "<ul class='in-tour'>" +
			"<li><strong>Print</strong><br/>Print details of all screens</li>" +
			"<li><strong>Download PDF</strong><br/>Download details of all screens in PDF</li>" +
			"<li><strong>Send SMS</strong><br/>Send link to current project via SMS</li>" +
			"<li><strong>Send e-mail</strong><br/>Send link to current project via e-mail</li>" +
			"<li><strong>Request a quote</strong><br/>Request a quote from squareV</li>" +
			"<li><strong>Generate read-only link</strong><br/>Show read-only link to share with your colleagues</li>" +
			"</ul>",
			target: "bottomRow",
			placement: "top",
			xOffset: 10,
			yOffset: -5
		},
		{
			title: "Project title",
			content: "Set your project title by clicking on it",
			target: "tour-project-title",
			placement: "bottom",
			xOffset: 'center',
			yOffset: -5
		},
		{
			title: "Screen title",
			content: "Set title for each screen in your project",
			target: "tour-screen-title",
			placement: "top",
			xOffset: 'center',
			yOffset: -5,
			arrowOffset: 'center'
		},
		{
			title: "Actions",
			content: "<ul class='in-tour'>" +
			"<li><strong>Walk through</strong><br/>Start over this walk through</li>" +
			"<li><strong>Add screen</strong><br/>Add another screen</li>" +
			"<li><strong>Duplicate screen</strong><br/>Creates copy of current screen</li>" +
			"<li><strong>Remove screen</strong><br/>Remove current screen</li>" +
			"<li><strong>Reset wall(s)</strong><br/>Reset current or all walls</li>" +
			"<li><strong>All</strong><br/>Show summation of all screens</li>" +
			"</ul>",
			target: "tour-actions",
			placement: "top",
			xOffset: -140,
			yOffset: -5,
			arrowOffset: '250'
		},
		{
			title: "Play video",
			content: "<ul class='in-tour'>" +
			"<li><strong>Add / Change video</strong><br/>Enter YouTube or Vimeo video url and play it on created screen(s)</li>" +
			"<li><strong>Turn off video</strong><br/>Remove current video</li>" +
			"<li><strong>Play video</strong><br/>Play (resume) selected video.</li>" +
			"<li><strong>Pause video</strong><br/>Pause current video</li>" +
			"</ul>",
			target: "video-actions",
			placement: "top",
			xOffset: -110,
			yOffset: -5,
			arrowOffset: 'center'
		},
		{
			title: "Screen details",
			content: "Information about current screen",
			target: "tour-screen-details",
			placement: "top",
			xOffset: 10,
			yOffset: -5
		}
	]
};

var hashCallout = {
	id: 'hash-callout',
	title: "Be sure to bookmark this page!",
	content: 'The URL is unique to your project. Bookmark or share it with your colleagues.<br/><br/><label style="cursor: pointer"><input type="checkbox" onclick="disableHashCallout()"/> Don\'t show this message in the future</label>',
	target: "hash-callout",
	placement: "bottom",
	xOffset: 150,
	yOffset: 5
};

var calloutMgr = hopscotch.getCalloutManager();

function disableHashCallout(){
	document.cookie = "hashCalloutDisabled=true; path=/";
	setTimeout(function(){
		calloutMgr.removeCallout('hash-callout');
	}, 1500);
}
