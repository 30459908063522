//start youtube code
var videoPlayers = [];
var isPlayerReady = false;
var isApiReady = false;


function onYouTubeIframeAPIReady() {
	isApiReady = true;
}

function onPlayerReady() {
	isPlayerReady = true;
	if(!(/iPad|iPhone|iPod/.test(navigator.platform))){
		playYoutubeVideo();
	}
}


function onPlayerStateChange(event) {
	var appElement = document.querySelector('[ng-app=square-v]');
	var $scope = angular.element(appElement).scope();
	if(event.data == 0){
		event.target.playVideo();
	} else {
		$scope.$apply(function() {
			$scope.videoState = event.data;
		});
	}
}


function playYoutubeVideo() {
	if(isPlayerReady) {
		$(videoPlayers).each(function(index){
			try {
				videoPlayers[index].mute();
				videoPlayers[index].playVideo();
			}
			catch(err) {
				internalLog(err)
			}
		})
	} else {
		setTimeout(playYoutubeVideo, 1000);
	}
}

function pauseYoutubeVideo() {
	if(isPlayerReady) {
		$(videoPlayers).each(function(index){
			videoPlayers[index].pauseVideo();
		})
	}
}

//Vimeo API

function onVimeoPlayerReady() {
	isPlayerReady = true;
	setTimeout(playVimeoVideo, 1000);
}

function onVimeoPlayerState(state) {
	var appElement = document.querySelector('[ng-app=square-v]');
	var $scope = angular.element(appElement).scope();

	$scope.$apply(function() {
		$scope.videoState = state;
	});
}

function onVimeoPlayerPlay() {
	onVimeoPlayerState(1)
}

function onVimeoPlayerPlayProgress() {
	onVimeoPlayerState(1)
}

function onVimeoPlayerPause() {
	onVimeoPlayerState(2)
}

function onVimeoPlayerFinish() {
	onVimeoPlayerState(0)
}


function playVimeoVideo() {
	if(isPlayerReady && videoPlayers.length > 0) {
		$(videoPlayers).each(function(index){
			videoPlayers[index].api('setVolume', 0);
			videoPlayers[index].api('play');
		})
	} else {
		setTimeout(playVimeoVideo, 1000);
	}
}


function pauseVimeoVideo() {
	if(isPlayerReady) {
		$(videoPlayers).each(function(index){
			videoPlayers[index].api('pause');
		})
	}
}

function internalLog(message){
	//console.log(message);
}

var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
