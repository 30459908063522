angular
    .module('square-v', ['ngResource', 'oitozero.ngSweetAlert', 'angular-carousel', 'ngCookies', '720kb.tooltips', 'angular-google-analytics', 'videosharing-embed', 'pingService', 'angular-websql', 'ngAnimate'])
    .config(['AnalyticsProvider', function (AnalyticsProvider) {
        //AnalyticsProvider.setAccount('UA-68045945-1');
        AnalyticsProvider.setAccount('UA-64490325-1');
    }])
    .run(['Analytics', '$interval', '$rootScope', '$window', 'ping', function (Analytics, $interval, $rootScope, $window, ping) {
        $rootScope.stripppedDomain = 'squarev.invested.pro';
        //$rootScope.stripppedDomain = 'calculator.squarev.co';
        //$rootScope.stripppedDomain = 'squarev.raz:9090';
        $rootScope.domain = '//' + $rootScope.stripppedDomain + '/';

        /*
        $window.Intercom("boot", {
            app_id: "a3644x02"
        });
*/

        $interval(function () {
            $window.Intercom("update");
        }, 10000);


        $rootScope.online = navigator.onLine;
        //$rootScope.online = false;
        $window.addEventListener("offline", function () {
            $rootScope.$apply(function () {
                $rootScope.online = false;
                jQuery("#intercom-container").hide();
            });
        }, false);

        $window.addEventListener("online", function () {
            $rootScope.$apply(function () {
                $rootScope.online = true;
                jQuery("#intercom-container").show();
            });
        }, false);


        $interval(function () {
            ping.ping($rootScope.domain + 'ping.txt', function(response) {
                if (response > 0) {
                    $rootScope.online = true;
                    jQuery("#intercom-container").show();
                } else {
                    $rootScope.online = false;
                    jQuery("#intercom-container").hide();
                }
            });
        }, 1000);
    }]);


/*
 if (isTouchDevice()) {
 var $body = jQuery('body');

 $(document)
 .on('focus', 'input, select', function () {
 $body.addClass('fixfixed');
 })
 .on('blur', 'input, select', function () {
 $body.removeClass('fixfixed');
 });
 }
 */

var preventScrollHandler = function (e) {
    e.preventDefault();
}

$(window).resize(function () {
    preventScroll();
});

var preventScroll = function () {
    $(document).scrollTop();
    if (window.matchMedia("(orientation: landscape)").matches && window.matchMedia("screen and (min-width: 980px) and (max-width: 1025px)")) {
        $(document).bind(
            'touchmove',
            preventScrollHandler
        );
    } else {
        $(document).unbind(
            'touchmove',
            preventScrollHandler
        );
    }
};

preventScroll();