angular.module('square-v')
    .controller('VisualizeController', ['$scope', '$rootScope', '$location', 'Storage', 'SweetAlert', '$http', '$q', '$timeout', 'Analytics', '$cookies', '$webSql', '$window', function ($scope, $rootScope, $location, Storage, SweetAlert, $http, $q, $timeout, Analytics, $cookies, $webSql, $window) {

        $scope.domain = 'http:' + $rootScope.domain;

        $rootScope.displayPanel = true;

        if (angular.isDefined(window.openDatabase)) {
            $scope.db = $webSql.openDatabase('squarev', '1.0', 'Sync DB', 2 * 1024 * 1024);
            $scope.db.createTable('projects', {
                "sync_id": {
                    "type": "INTEGER",
                    "null": "NOT NULL", // default is "NULL" (if not defined)
                    "primary": true, // primary
                    "auto_increment": true // auto increment
                },
                "id": {
                    "type": "INTEGER",
                },
                "settings": {
                    "type": "TEXT"
                },
                "project_title": {
                    "type": "TEXT"
                },
                "screens_data": {
                    "type": "TEXT"
                },
                "origin": {
                    "type": "INTEGER" //0=local, 1=online
                }
            })
        } else {
            $scope.db = false;
        }

        Analytics.trackPage('/');

        var metricToImperialFactor = 3.2808399;

        $scope.isClean = true;

        $scope.isMobile = function () {
            var mql = window.matchMedia("screen and (max-width: 992px)");
            return mql.matches;
        }

        $scope.isIos = function () {
            return (/iPad|iPhone|iPod/.test(navigator.platform));
            //return true;
        }

        $scope.readOnly = true;

        $scope.screenOptions = {
            units: {
                'imperial': 'Imperial (FT)',
                'metric': 'Metric (M)',
                'panels': 'Panels'
            },
            product: [],
            ratio: {
                'custom': {id: 'custom', name: 'Custom', x: 0, y: 0},
                '16x9': {id: '16x9', name: '16x9', 'x': 16, 'y': 9},
                '4x3': {id: '4x3', name: '4x3', x: 4, y: 3},
                '21x9': {id: '21x9', name: '21x9', x: 21, y: 9}
            }
        }

        $scope.settingsRemote = JSON.parse(globalProducts)

        $scope.settings = {
            panel: {}
        }

        angular.forEach($scope.settingsRemote, function (item) {
            $scope.settings.panel[item.id] = item;
            $scope.screenOptions.product.push(item);
        })

        var defaultScreenSettings = {
            units: $scope.screenOptions.units.imperial, //imperial, metric
            product: $scope.screenOptions.product[2], //V3
            ratio: $scope.screenOptions.ratio['custom'],
            vertical: 9,
            horizontal: 16,
            panels: [],
            edited: false
        };

        var defaultScreenCalculated = {
            horizontalPanels: 0,
            verticalPanels: 0
        }

        $scope.generalSettings = {
            voltage: '110',
            videoUrl: 'https://www.youtube.com/watch?v=0pXYp72dwl0'
        };


        /*
         var path = $location.path();
         var hash = path.replace("/", "");
         $scope.hash = hash;
         */

        var manualLocationChange = false;

        $rootScope.$on("$locationChangeStart", function (event, next, current) {

            var path = $location.path();
            var hash = path.replace("/", "");

            if (hash === '') {
                $scope.readOnly = false;
                $rootScope.displayPanel = true;
            }

            if (!manualLocationChange) {
                $scope.syncId = null;
                $scope.hash = hash;
                $scope.updateFromHash();
            }
            manualLocationChange = false;

            //sweetAlert.close();
        });


        var lastUpdateToApi = '';

        var storageData;

        var loadedFromApi = false;

        var swalCheckbox = '<label><input type="checkbox" id="ssm_read_only" value="1" placeholder="Send read-only link" style="display: inline-block; float: left; height: 20px; margin: 0;padding: 0;width: 20px;line-height: 16px; margin-right: 10px"/> <span style="float: left">Send read-only link</span></label>';

        $scope.maxScreens = 4;

        $scope.isScreenEdited = function (screen) {
            if ($scope.screens[screen].edited) {
                return true;
            }

            if ($scope.screens[screen].panelsEdited) {
                $scope.screens[screen].edited = true;
                return true;
            }

            if ($scope.screens[screen].vertical !== defaultScreenSettings.vertical) {
                $scope.screens[screen].edited = true;
                return true;
            }

            if ($scope.screens[screen].horizontal !== defaultScreenSettings.horizontal) {
                $scope.screens[screen].edited = true;
                return true;
            }

            if ($scope.screens[screen].ratio !== defaultScreenSettings.ratio) {
                $scope.screens[screen].edited = true;
                return true;
            }

            if ($scope.screens[screen].product !== defaultScreenSettings.product) {
                $scope.screens[screen].edited = true;
                return true;
            }

            if ($scope.screens[screen].units !== defaultScreenSettings.units) {
                $scope.screens[screen].edited = true;
                return true;
            }

            return false;
        }

        $scope.calculateScreenData = function (screen) {
            if ($scope.screensCalculated == undefined) {
                $scope.screensCalculated = {};
            }
            if ($scope.screensCalculated[screen] == undefined) {
                $scope.screensCalculated[screen] = cloneObject(defaultScreenCalculated);
            }
            if ($scope.screens[screen].units == $scope.screenOptions.units.metric) {
                $scope.screensCalculated[screen].horizontalPanels = Math.round(($scope.screens[screen].horizontal * (1000 / $scope.settings.panel[$scope.screens[screen].product.id].width)));
                $scope.screensCalculated[screen].verticalPanels = Math.round(($scope.screens[screen].vertical * (1000 / $scope.settings.panel[$scope.screens[screen].product.id].height)));
            } else if ($scope.screens[screen].units == $scope.screenOptions.units.imperial) {
                $scope.screensCalculated[screen].horizontalPanels = Math.round($scope.screens[screen].horizontal / metricToImperialFactor * (1000 / $scope.settings.panel[$scope.screens[screen].product.id].width));
                $scope.screensCalculated[screen].verticalPanels = Math.round($scope.screens[screen].vertical / metricToImperialFactor * (1000 / $scope.settings.panel[$scope.screens[screen].product.id].height));
            } else {
                $scope.screensCalculated[screen].horizontalPanels = Math.round($scope.screens[screen].horizontal);
                $scope.screensCalculated[screen].verticalPanels = Math.round($scope.screens[screen].vertical);
            }

            var leftOffset = 0;
            var rightOffset = 0;
            var topOffset = 0;
            var bottomOffset = 0;

            for (var i = 0; i < $scope.screens[screen].panels.length; i++) {
                if ($scope.screens[screen].panels[i].xOffset === -1) {
                    leftOffset = 0.5;
                }

                if ($scope.screens[screen].panels[i].xOffset === 1) {
                    rightOffset = 0.5;
                }

                if ($scope.screens[screen].panels[i].yOffset === -1) {
                    topOffset = 0.5
                }

                if ($scope.screens[screen].panels[i].yOffset === 1) {
                    bottomOffset = 0.5
                }
            }

            $scope.screensCalculated[screen].horizontalPanelsOffset = leftOffset + rightOffset;
            $scope.screensCalculated[screen].verticalPanelsOffset = topOffset + bottomOffset;

            $scope.screensCalculated[screen].metric = {
                width: $scope.screensCalculated[screen].horizontalPanels * ($scope.settings.panel[$scope.screens[screen].product.id].width / 1000),
                height: $scope.screensCalculated[screen].verticalPanels * ($scope.settings.panel[$scope.screens[screen].product.id].height / 1000)
            };

            $scope.screensCalculated[screen].imperial = {
                width: $scope.screensCalculated[screen].metric.width * metricToImperialFactor,
                height: $scope.screensCalculated[screen].metric.height * metricToImperialFactor
            };

            $scope.screensCalculated[screen].metric.diagonal = Math.sqrt(($scope.screensCalculated[screen].metric.width * $scope.screensCalculated[screen].metric.width) + ($scope.screensCalculated[screen].metric.height * $scope.screensCalculated[screen].metric.height))
            $scope.screensCalculated[screen].imperial.diagonal = Math.sqrt(($scope.screensCalculated[screen].imperial.width * $scope.screensCalculated[screen].imperial.width) + ($scope.screensCalculated[screen].imperial.height * $scope.screensCalculated[screen].imperial.height))

            var total = ($scope.screensCalculated[screen].verticalPanels) * ($scope.screensCalculated[screen].horizontalPanels);


            if (total !== $scope.screens[screen].panels.length) {
                var panels = [];

                for (var i = 0; i < total; i++) {
                    panels[i] = {
                        enabled: true,
                        xOffset: 0,
                        yOffset: 0
                    };
                }

                $scope.screens[screen].panels = panels;
            }
        }

        $rootScope.$watch('online', function (newval, oldval) {
            //sync to remote when back online
            if (newval === true && oldval === false) {
                $scope.saveToRemoteStorage();
            }
        });

        $scope.uploadingStatus = '';
        $scope.saveToRemoteStorage = function () {
            if ($scope.db) {
                $scope.db.selectAll("projects").then(function (results) {
                    if (results.rows.length > 0) {
                        $scope.uploadingStatus = 'Uploading';
                    } else {
                        $scope.uploadingStatus = '';
                    }

                    var count = 1;
                    angular.forEach(results.rows, function (row) {
                        if (row.origin === 0) {
                            storageData = Storage.save({
                                hash: row.id,
                                project_title: row.project_title,
                                general_settings: row.settings,
                                screens_data: row.screens_data
                            }, function (data) {
                                $scope.db.del("projects", {"id": row.id})
                                if ($scope.hash === row.id) {
                                    $scope.updateFromHash();
                                }
                                $scope.uploadingStatus = 'Uploading ' + count + '/' + results.rows.length;
                                count++;
                                if (count >= results.rows.length) {
                                    $scope.uploadingStatus = '';
                                }
                            }, function (response) {

                            })
                        } else {
                            var storageDataUpdate = {
                                data: {}
                            }

                            storageDataUpdate.data.project_title = row.project_title;
                            storageDataUpdate.data.general_settings = row.settings;
                            storageDataUpdate.data.screens_data = row.screens_data;

                            Storage.update(
                                {id: row.id},
                                storageDataUpdate,
                                function () {
                                    $scope.db.del("projects", {"id": row.id})
                                    if ($scope.hash === row.id) {
                                        $scope.updateFromHash();
                                    }
                                    $scope.uploadingStatus = 'Uploading ' + count + '/' + results.rows.length;
                                    count++;
                                    if (count >= results.rows.length) {
                                        $scope.uploadingStatus = '';
                                    }
                                },
                                function (response) {
                                }
                            );
                        }
                    });
                })
            }
        }

        $scope.saveToRemoteStorage();

        $scope.createCacheStorage = function (hash, origin) {
            var deferred = $q.defer();

            if ($scope.db) {
                $scope.db.select("projects", {
                    "id": {
                        "value": hash
                    }
                }).then(function (results) {
                    console.log(results);
                    if (results.rows.length === 0) {
                        $scope.db.insert('projects', {
                            "id": hash,
                            'settings': JSON.stringify($scope.generalSettings),
                            'project_title': $scope.projectTitle,
                            'screens_data': JSON.stringify($scope.screens),
                            'origin': origin
                        }).then(function (results) {
                            deferred.resolve(results.insertId)
                        }, function (err) {
                            deferred.reject('error');
                        });
                    } else {
                        $scope.syncId = results.rows[0].sync_id;
                        return $scope.updateCacheStorage();
                    }
                })
            } else {
                deferred.resolve(false)
            }

            return deferred.promise;
        }

        $scope.updateCacheStorage = function () {
            var deferred = $q.defer();

            if($scope.db){
            $scope.db.update('projects', {
                'settings': JSON.stringify($scope.generalSettings),
                'project_title': $scope.projectTitle,
                'screens_data': JSON.stringify($scope.screens)
            }, {
                id: $scope.hash
            }).then(function (results) {
                deferred.resolve('success')
            }, function (err) {
                deferred.reject('error');
            });
            } else {
                deferred.resolve(false)
            }

            return deferred.promise;
        }

        $scope.updateStorage = function (forceInsert, forceUpdate, disableCallout) {
            //console.log('UpdateStorage');
            var deferred = $q.defer();

            //First check if data to be stored is different than previous one. It prevents to much calls to API
            deferred.notify('Updating');

            if (!$rootScope.online) {
                var origin = 1;
                if ($scope.hash === '') {
                    hash = Date.now();
                    origin = 0;
                } else {
                    hash = $scope.hash;
                }
                if ($scope.syncId === '' || $scope.syncId === null || angular.isUndefined($scope.syncId)) {
                    $scope.createCacheStorage(hash, origin).then(function (syncId) {
                        $scope.hash = hash;
                        $scope.syncId = syncId;
                        $scope.hashReadOnly = 'ro' + hash;
                        manualLocationChange = true;
                        $location.path('/' + hash);
                    }, function () {
                        SweetAlert.swal(
                            {
                                title: "Error",
                                text: "There was error trying to save your project to cache",
                                showConfirmButton: true,
                                type: 'error'
                            });
                    });
                } else {
                    $scope.updateCacheStorage().then(function (syncId) {
                    }, function () {
                        SweetAlert.swal(
                            {
                                title: "Error",
                                text: "There was error trying to save your project to cache",
                                showConfirmButton: true,
                                type: 'error'
                            });
                    });
                }

                deferred.resolve(hash);
                return deferred.promise;
            }

            if (typeof disableCallout === 'undefined') {
                disableCallout = false;
            }

            if ($scope.readOnly) {
                deferred.resolve('no update');
                return deferred.promise;
            }
            if ($scope.hash === '') {
                if ($scope.isScreenEdited($scope.currentScreen) || forceInsert) {
                    //console.log('Insert');
                    storageData = Storage.save({
                        project_title: $scope.projectTitle,
                        general_settings: JSON.stringify($scope.generalSettings),
                        screens_data: JSON.stringify($scope.screens)
                    }, function (data) {
                        hash = data.data.url;
                        $scope.hash = hash;
                        $scope.hashReadOnly = data.data.read_only
                        manualLocationChange = true;
                        $location.path('/' + hash);
                        $window.Intercom('update', {"last_project": $scope.domain + '#/' + hash});
                        deferred.resolve(hash);

                        Analytics.trackEvent('Project', 'Save project', 'Hash', hash, true);
                        $window.Intercom('trackEvent', 'Save project', {
                            'hash': hash
                        });

                        if (!disableCallout) {
                            if ($cookies.hashCalloutDisabled === undefined || !$cookies.hashCalloutDisabled) {
                                calloutMgr.removeCallout('hash-callout');
                                calloutMgr.createCallout(hashCallout);
                                $timeout(function () {
                                    calloutMgr.removeCallout('hash-callout');
                                }, 10000);
                            }
                        }
                    }, function (response) {
                        var responseText = 'Lost connection with the server. An internet connection is needed to continue.';
                        if (response.status !== 0) {
                            responseText = response.status + ': ' + response.statusText;
                        }
                        SweetAlert.swal({
                            title: "Save error",
                            text: responseText,
                            type: "error",
                            closeOnConfirm: true,
                            confirmButtonColor: "#4cb74c"
                        });
                    })
                } else {
                    deferred.resolve('no update');
                }
            } else {
                if (lastUpdateToApi != JSON.stringify($scope.screens) || forceUpdate) {
                    //console.log('Update');
                    if (storageData.data != undefined) {
                        storageData.data.project_title = $scope.projectTitle,
                            storageData.data.general_settings = JSON.stringify($scope.generalSettings),
                            storageData.data.screens_data = JSON.stringify($scope.screens);
                        Storage.update(
                            {id: $scope.hash},
                            storageData,
                            function () {
                                deferred.resolve($scope.hash);
                            },
                            function (response) {
                                var responseText = 'Lost connection with the server. An internet connection is needed to continue.';
                                if (response.status !== 0) {
                                    responseText = response.status + ': ' + response.statusText;
                                }
                                SweetAlert.swal({
                                    title: "Update error",
                                    text: responseText,
                                    type: "error",
                                    closeOnConfirm: true,
                                    confirmButtonColor: "#4cb74c"
                                });
                            }
                        );
                    } else {
                        deferred.resolve('no update');
                    }
                } else {
                    deferred.resolve('no update');
                }
            }
            lastUpdateToApi = JSON.stringify($scope.screens);
            return deferred.promise;
        }

        $scope.loadCounter = 0;
        $scope.updateFromHash = function () {
            if ($scope.hash !== '') {

                $rootScope.displayPanel = false;

                if (!$rootScope.online) {
                    SweetAlert.swal({
                        title: "Offline mode",
                        text: "You can't load projects from server in offline mode.",
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: false
                    }, function (response) {
                        if ($rootScope.online) {
                            $scope.updateFromHash();
                        } else {
                            $scope.resetAllWalls();
                        }
                    });
                    return;
                }
                $scope.isClean = false;
//				console.log('Get');
                storageData = Storage.get({id: $scope.hash}, function (data) {
                    var screensJson = data.data.screens_data;
                    $scope.hashReadOnly = data.data.read_only;
                    $scope.loadCounter = 0;
                    try {
                        var screens = JSON.parse(screensJson);
                        for (var i = 0; i < screens.length; i++) {
                            screens[i].product = findById($scope.screenOptions.product, screens[i].product.id);
                            screens[i].ratio = $scope.screenOptions.ratio[screens[i].ratio.id]
                        }

                        loadedFromApi = true;
                        $scope.screens = screens;
                        $scope.checkMove();
                        if (data.data.project_title !== null && data.data.project_title !== "") {
                            $scope.projectTitle = data.data.project_title;
                        }
                        $scope.generalSettings = JSON.parse(data.data.settings);
                        if ($scope.generalSettings.voltage === '240') {
                            $scope.generalSettings.voltage = '208';
                        }
                        for (var i = 0; i < screens.length; i++) {
                            $scope.calculateScreenData(i);
                        }
                        lastUpdateToApi = JSON.stringify($scope.screens);
                        if ($scope.hash.substring(0, 2) === 'ro') {
                            $scope.readOnly = true;
                        } else {
                            $scope.readOnly = false;
                        }
                    } catch (e) {
                        //console.log('Storage hash given but response data is not valid JSON');
                    }
                }, function (response) {
                    if (response.status == 404) {
                        $scope.loadCounter++;
                        if ($scope.loadCounter <= 4) {
                            $timeout(function () {
                                $scope.updateFromHash();
                            }, 1000);
                        } else {
                            SweetAlert.swal({
                                title: "Wall not found!",
                                text: "Requested video wall does not exist. Please make sure that project URL is correct!",
                                type: "error",
                                /*
                                 closeOnConfirm: true,
                                 confirmButtonColor: "#4cb74c",
                                 confirmButtonText: 'Start new video wall',
                                 */
                                showConfirmButton: false,
                                allowOutsideClick: false
                            });
                        }
                    } else {
                        SweetAlert.swal({
                            title: "Error " + response.status,
                            text: response.statusText,
                            type: "error",
                            /*
                             closeOnConfirm: true,
                             confirmButtonColor: "#4cb74c",
                             confirmButtonText: 'Start new video wall',
                             */
                            showConfirmButton: false,
                            allowOutsideClick: false
                        });
                    }
                });
            } else {
                $scope.screens = [
                    cloneObject(defaultScreenSettings)
                ];
                $scope.calculateScreenData(0);
                $scope.isClean = true;

                $rootScope.displayPanel = true;

                /*
                if ($scope.isMobile()) {
                    $timeout(function () {
                        $scope.showCleanMobileNotification();
                    }, 1000);
                }*/
            }

            $scope.currentScreen = 0;
            //lastUpdateToApi = JSON.stringify($scope.screens);

            //lastPanelCountHaystack = [];
            //calculatedData  = {};
        }

        $scope.showCleanMobileNotification = function () {
            SweetAlert.swal({
                title: "Output only",
                text: "You're looking at the output only view. If you'd like to build your own custom wall, come back on a tablet or desktop.<br/><br/>" +
                    "Send yourself a reminder:" +
                    '<input type="text" id="sr_email" placeholder="Email address" style="display: block"/>',
                type: "info",
                showCancelButton: true,
                closeOnConfirm: true,
                confirmButtonColor: "#4cb74c",
                html: true
            }, function (confirm) {
                if (confirm === false) return false;

                var sr_email = jQuery('#sr_email').val();

                if (sr_email === "") {
                    $timeout(function () {
                        $scope.unknownError('Enter email', 'If you want to send reminder please fill in your email address', $scope.showCleanMobileNotification);
                    }, 500);
                    return false
                }

                $http.post('/actions/reminder', {
                    email: sr_email
                }).success(function (data, status, headers, config) {
                    SweetAlert.swal(
                        {
                            title: "Reminder sent",
                            text: "Reminder was sent!",
                            type: "success",
                            confirmButtonColor: "#4cb74c",
                        });
                }).error(function (data, status, headers, config) {
                    SweetAlert.swal(
                        {
                            title: "Reminder sending error",
                            text: "We couldn't send you a reminder. Please try again!",
                            type: "error",
                            confirmButtonColor: "#4cb74c",
                        });
                });
            });
        }

        $scope.showDirtyMobileNotification = function () {
            var modalText = 'This is the mobile version... still useful, but slightly limited. You can create/edit 1 screen at a time, or view others created on desktop.<br>';
            if ($scope.hash) {
                modalText += '<br/>Share your project, here\'s the link: <div style="overflow: hidden; text-overflow: ellipsis; text-transform: none"><a href="' + $scope.domain + '#/' + $scope.hash + '" target="_blank">' + $scope.domain + '#/' + $scope.hash + '</a></div>'
            }
            modalText += '<div style="min-height: 100px"><button id="resetToNewProject" style="margin-top: 10px;">Reset to a new project?</button><button style="margin-top: 10px;" id="needHelp">Need help? Ask us anything</button></div>'
            SweetAlert.swal({
                title: "Mobile version",
                text: modalText,
                type: "info",
                html: true,
                closeOnConfirm: true,
                animation: "",
                confirmButtonColor: "#4cb74c",
                customClass: "top-position",
                allowOutsideClick: true,
            });
        }

        $scope.addScreen = function (clone) {
            $scope.currentScreen = $scope.screens.length;
            if (clone === true) {
                $scope.screens.push(cloneObject($scope.screens[$scope.cloneScreen]));
                $scope.screens[$scope.currentScreen].panels = jQuery.extend(true, [], $scope.screens[$scope.cloneScreen].panels);
                Analytics.trackEvent('Screen', 'Duplicate screen', 'Screen no.', $scope.screens.length);
                $window.Intercom('trackEvent', 'Duplicate screen', {
                    'number_of_screens': $scope.screens.length
                });
            } else {
                $scope.screens.push(cloneObject(defaultScreenSettings))
                Analytics.trackEvent('Screen', 'Add screen', 'Screen no.', $scope.screens.length);
                $window.Intercom('trackEvent', 'Add screen', {
                    'number_of_screens': $scope.screens.length
                });
            }
            $scope.calculateScreenData($scope.currentScreen);
        }

        $scope.screenSelectorVisible = false;

        $scope.toggleScreenSelector = function () {
            $scope.screenSelectorVisible = !$scope.screenSelectorVisible;
        }

        $scope.lastScreen = 0;

        $scope.selectScreen = function (index) {
            if ($scope.currentScreen !== index) {
                if (index === -1) {
                    $scope.lastScreen = $scope.currentScreen;
                }

                $scope.currentScreen = index;
            } else if ($scope.currentScreen === -1) {
                $scope.currentScreen = $scope.lastScreen;
            }
        }

        $scope.previousScreen = function () {
            if ($scope.currentScreen == 0 && $scope.isMobile()) {
                return;
            }

            $scope.currentScreen--;

            if ($scope.currentScreen < -1) {
                $scope.currentScreen = $scope.totalScreens() - 1;
            }
        }

        $scope.toggleMenuPanel = function () {
            $rootScope.displayPanel = !$rootScope.displayPanel;
        }

        $scope.nextScreen = function (add, clone) {
            var tempCurrentScreen = $scope.currentScreen;

            if ((tempCurrentScreen + 1 >= $scope.totalScreens() && !$scope.isScreenEdited(tempCurrentScreen)) || (tempCurrentScreen + 1 >= $scope.totalScreens())) {

                if ($scope.isMobile()) {
                    return;
                }

                if ($scope.readOnly) {
                    $scope.currentScreen = -1;
                    return;
                }
            }

            if (add === true) {
                tempCurrentScreen = $scope.screens.length - 1;
            }

            if (clone === true) {
                $scope.cloneScreen = $scope.currentScreen;
            }

            if (tempCurrentScreen >= $scope.maxScreens - 1) {
                SweetAlert.swal({
                    title: "Limit!",
                    text: "You already set up maximun number (" + $scope.maxScreens + ") of allowed screens",
                    type: "error",
                    closeOnConfirm: true,
                    confirmButtonColor: "#4cb74c"
                });
                return;
            }
            if ((tempCurrentScreen + 1 >= $scope.totalScreens() && !$scope.isScreenEdited(tempCurrentScreen)) && (clone !== true)) {
                SweetAlert.swal({
                        title: "Add another screen?",
                        text: "ARE YOU SURE YOU WANT TO ADD ANOTHER SCREEN? YOU DIDN'T EDIT CURRENT SCREEN YET.",
                        type: "warning",
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonColor: "#4cb74c",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $scope.currentScreen = tempCurrentScreen;
                            $scope.addScreen(clone);
                        }
                    });
            } else if (tempCurrentScreen + 1 >= $scope.totalScreens()) {
                $scope.currentScreen = tempCurrentScreen;
                $scope.addScreen(clone);
            } else {
                $scope.currentScreen = tempCurrentScreen + 1;
            }
        }

        var lastPanelCountHaystack = [];

        $scope.totalPanels = function (screen) {
            if (screen == undefined && $scope.currentScreen !== -1) {
                screen = $scope.currentScreen;
            }
            if ($scope.screens !== undefined && screen !== undefined) {
                var count = 0;
                if (lastPanelCountHaystack[screen] === undefined || (lastPanelCountHaystack[screen]) !== JSON.stringify($scope.screens[screen].panels)) {
                    jQuery.each($scope.screens[screen].panels, function (index, data) {
                        if (data.enabled) {
                            count++;
                        }
                    });

                    lastPanelCountHaystack[screen] = JSON.stringify($scope.screens[screen].panels);
                    $scope.screensCalculated[screen].panelsCount = count;
                } else {
                    count = $scope.screensCalculated[screen].panelsCount;
                }
                return count;
            } else if ($scope.screens !== undefined) {
                var totalPanelsAll = 0;
                for (var i = 0; i < $scope.screens.length; i++) {
                    totalPanelsAll = totalPanelsAll + $scope.totalPanels(i);
                }
                return totalPanelsAll;
            } else {
                return 0;
            }
        };

        $scope.totalPixels = function (screen, all) {
            if (typeof all === "undefined") {
                all = false;
            }
            if ((screen === null || typeof screen === "undefined") && $scope.currentScreen !== -1) {
                screen = $scope.currentScreen;
            }
            if (typeof $scope.screens !== "undefined" && typeof screen !== "undefined" && screen !== null) {
                var panels = 0;
                if (all) {
                    panels = $scope.screens[screen].panels.length;

                    var leftOffset = 0;
                    var rightOffset = 0;
                    var topOffset = 0;
                    var bottomOffset = 0;

                    for (var i = 0; i < $scope.screens[screen].panels.length; i++) {
                        if ($scope.screens[screen].panels[i].xOffset === -1) {
                            leftOffset = 0.5;
                        }

                        if ($scope.screens[screen].panels[i].xOffset === 1) {
                            rightOffset = 0.5;
                        }

                        if ($scope.screens[screen].panels[i].yOffset === -1) {
                            topOffset = 0.5
                        }

                        if ($scope.screens[screen].panels[i].yOffset === 1) {
                            bottomOffset = 0.5
                        }
                    }

                    panels = panels + leftOffset + rightOffset + topOffset + bottomOffset;
                } else {
                    panels = $scope.totalPanels(screen);
                }
                return ($scope.settings.panel[$scope.screens[screen].product.id].pixels_height * $scope.settings.panel[$scope.screens[screen].product.id].pixels_width * panels);
            } else if ($scope.screens !== undefined) {
                var totalPixelsAll = 0;
                for (var i = 0; i < $scope.screens.length; i++) {
                    totalPixelsAll = totalPixelsAll + $scope.totalPixels(i, all);
                }
                return totalPixelsAll;
            } else {
                return 0;
            }
        };

        $scope.totalPorts = function () {
            //Ceil
            if ($scope.currentScreen === -1) {
                var totalPorts = 0;
                for (var i = 0; i < $scope.screens.length; i++) {
                    totalPorts = totalPorts + Math.ceil($scope.totalPixels(i, true) / 430559);
                }
                return totalPorts;
            } else {
                return Math.ceil($scope.totalPixels(null, true) / 430559);
            }
        }

        $scope.totalWeight = function (screen) {
            if (screen == undefined && $scope.currentScreen !== -1) {
                screen = $scope.currentScreen;
            }
            if ($scope.screens !== undefined && screen !== undefined) {
                return ($scope.totalPanels(screen) * $scope.settings.panel[$scope.screens[screen].product.id].weight_total * (($scope.screens[screen].units == $scope.screenOptions.units.metric) ? 1 : 2.20462262));
            } else if ($scope.screens !== undefined) {
                var total = 0;
                for (var i = 0; i < $scope.screens.length; i++) {
                    total = total + $scope.totalWeight(i);
                }
                return total;
            } else {
                return 0;
            }
        };

        $scope.totalDraw = function (screen) {
            if (screen == undefined && $scope.currentScreen !== -1) {
                screen = $scope.currentScreen;
            }
            if ($scope.screens !== undefined && screen !== undefined) {
                var ampDraw = $scope.settings.panel[$scope.screens[screen].product.id].power_110;
                if ($scope.generalSettings.voltage == '208') {
                    ampDraw = $scope.settings.panel[$scope.screens[screen].product.id].power_208;
                }
                return ($scope.totalPanels(screen) * ampDraw)
            } else if ($scope.screens !== undefined) {
                var total = 0;
                for (var i = 0; i < $scope.screens.length; i++) {
                    total = total + $scope.totalDraw(i);
                }
                return total;
            } else {
                return 0;
            }
        };

        $scope.totalCircuits = function (screen) {
            if (screen == undefined && $scope.currentScreen !== -1) {
                screen = $scope.currentScreen;
            }
            if ($scope.screens !== undefined && screen !== undefined) {
                var ampDraw = $scope.settings.panel[$scope.screens[screen].product.id].power_110;
                if ($scope.generalSettings.voltage == '208') {
                    ampDraw = $scope.settings.panel[$scope.screens[screen].product.id].power_208;
                }
                return Math.ceil($scope.totalPanels(screen) * ampDraw / 20);
            } else if ($scope.screens !== undefined) {
                var total = 0;
                for (var i = 0; i < $scope.screens.length; i++) {
                    total = total + $scope.totalCircuits(i);
                }
                return total;
            } else {
                return 0;
            }
        };

        $scope.totalScreens = function () {
            if ($scope.screens !== undefined) {
                return $scope.screens.length;
            } else {
                return 0;
            }
        };

        $scope.numberToString = function (num) {
            var units = new Array("Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen");
            return units[num];
        }

        $scope.togglePanel = function (screen, panel) {
            if ($scope.readOnly) {
                return;
            }

            if (($scope.videoState !== undefined && $scope.videoState !== -1) && $scope.isIos()) {
                return;
            }

            if ($scope.totalPanels(screen) < 2 && $scope.screens[screen].panels[panel].enabled) {
                $scope.unknownError('Last panel', 'You can not remove last panel');
                return;
            }
            $scope.screens[screen].panels[panel].enabled = !$scope.screens[screen].panels[panel].enabled;
            $scope.screens[screen].panelsEdited = true;
            Analytics.trackEvent('Screen', 'Toggle panel', screen);
            $window.Intercom('trackEvent', 'Toggle panel', {});
            $scope.updateStorage();
        }

        $scope.updateScreenTitle = function (screen, title) {
            $scope.screens[screen].title = title;
            $scope.updateStorage(true, true);
        }

        $scope.unknownError = function (title, msg, afterFunction) {
            SweetAlert.swal(
                {
                    title: title,
                    text: msg,
                    type: "error",
                    confirmButtonColor: "#4cb74c"
                }, function (isConfirm) {
                    if (isConfirm && afterFunction !== undefined) {
                        $timeout(afterFunction, 500);
                    }
                });
        }

        $scope.generatingAlertHandler = function () {
            sweetAlert.close();
            $(window).unbind('blur', $scope.generatingAlertHandler);
        }

        $scope.downloadPdf = function () {
            if (!$rootScope.online) {
                $scope.showOfflineMessage();
                return;
            }

            SweetAlert.swal(
                {
                    title: "Generating document",
                    text: "This shouldn't take more than a few seconds.",
                    showConfirmButton: true,
                    imageUrl: "/img/swal-loading.gif"
                }, function () {
                    $(window).unbind('blur', $scope.generatingAlertHandler);
                });

            $timeout(function () {
                $scope.generatingAlertHandler();
            }, 20000);

            $(window).bind('blur', $scope.generatingAlertHandler);

            var promise = $scope.updateStorage(true, true);
            promise.then(function (greeting) {

                Analytics.trackEvent('Share', 'Download PDF', $scope.hash);
                $window.Intercom('trackEvent', 'Download PDF', {});

                url = '/actions/pdf/' + $scope.hash;
                var iframe = this._downloadIframe;
                if (!this._downloadIframe) {
                    iframe = this._downloadIframe = document.createElement('iframe');
                    document.body.appendChild(iframe);
                    iframe.style.display = 'none';
                }

                iframe.src = url;
            }, function (reason) {
                $scope.unknownError('Something went wrong', reason);
            });
        }


        $scope.printPdf = function () {
            if (!$rootScope.online) {
                $scope.showOfflineMessage();
                return;
            }

            SweetAlert.swal(
                {
                    title: "Generating document",
                    text: "This shouldn't take more than a few seconds.",
                    showConfirmButton: true,
                    imageUrl: "/img/swal-loading.gif"
                }, function () {
                    $(window).unbind('blur', $scope.generatingAlertHandler);
                });

            $timeout(function () {
                $scope.generatingAlertHandler();
            }, 20000);

            $(window).bind('blur', $scope.generatingAlertHandler);

            var promise = $scope.updateStorage(true, true);
            promise.then(function (greeting) {

                Analytics.trackEvent('Share', 'Print', $scope.hash);
                $window.Intercom('trackEvent', 'Print', {});

                url = '/actions/print/' + $scope.hash;
                var iframe = this._printIframe;
                if (!this._printIframe) {
                    iframe = this._printIframe = document.createElement('iframe');
                    document.body.appendChild(iframe);

                    iframe.style.display = 'none';
                    iframe.onload = function () {
                        setTimeout(function () {
                            iframe.focus();
                            iframe.contentWindow.print();
                        }, 1);
                    };
                }

                iframe.src = url;
            }, function (reason) {
                $scope.unknownError('Something went wrong', reason);
            });
        }

        $scope.getReadOnlyLink = function () {
            var promise = $scope.updateStorage(true, true, true);
            promise.then(function (greeting) {

                Analytics.trackEvent('Share', 'Get read-only link', $scope.hash);
                $window.Intercom('trackEvent', 'Get read-only link', {});
                $timeout(function () {
                    SweetAlert.swal(
                        {
                            title: "Read-only URL",
                            text: '<a href="' + $scope.domain + '#/' + $scope.hashReadOnly + '" target="_blank">' + $scope.domain + '#/' + $scope.hashReadOnly + '</a>',
                            showConfirmButton: true,
                            type: "info",
                            customClass: 'no-upper-case',
                            html: true
                        });
                }, 800);
            }, function (reason) {
                $scope.unknownError('Something went wrong', reason);
            });
        }

        $scope.sendSms = function () {
            if (!$rootScope.online) {
                $scope.showOfflineMessage();
                return;
            }

            if ($scope.readOnly) {
                var swalCheckboxTemp = '';
            } else {
                var swalCheckboxTemp = swalCheckbox;
            }

            SweetAlert.swal(
                {
                    title: "Share via SMS!",
                    text: "ENTER A PHONE NUMBER TO SEND YOUR CUSTOM PROJECT URL:" +
                        '<input type="text" id="st_your_name" placeholder="Your name" style="display: block"/>' +
                        '<input type="email" id="st_friends_mobile"  placeholder="Mobile number" style="display: block"/>' +
                        swalCheckboxTemp,
                    type: "info",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    animation: "slide-from-top",
                    confirmButtonColor: "#4cb74c",
                    html: true
                }, function (confirm) {
                    if (confirm === false) return false;

                    var st_your_name = jQuery('#st_your_name').val();
                    var st_friends_mobile = jQuery('#st_friends_mobile').val();
                    var ssm_read_only = jQuery('#ssm_read_only').is(':checked') ? '1' : '0';

                    if (st_your_name === "" || st_friends_mobile === '') {
                        $timeout(function () {
                            $scope.unknownError('Fill all fields', 'You need to fill both inputs!');
                        }, 500);
                        return false
                    }

                    var promise = $scope.updateStorage(true);
                    promise.then(function (greeting) {
                        $http.post('/actions/sms/' + $scope.hash, {
                            mobile: st_friends_mobile,
                            friend_name: st_your_name,
                            read_only: ssm_read_only
                        }).success(function (data, status, headers, config) {
                            Analytics.trackEvent('Share', 'Send via SMS', $scope.hash);
                            $window.Intercom('trackEvent', 'Send via SMS', {});

                            SweetAlert.swal(
                                {
                                    title: "Message sent",
                                    text: "Your message was sent!",
                                    type: "success",
                                    confirmButtonColor: "#4cb74c",
                                });
                        }).error(function (data, status, headers, config) {
                            SweetAlert.swal(
                                {
                                    title: "Message sending error",
                                    text: "We couldn't send your message. Please try again!",
                                    type: "error",
                                    confirmButtonColor: "#4cb74c",
                                });
                        });

                    }, function (reason) {
                        $scope.unknownError('Something went wrong', reason);
                    });
                }
            );
        }

        $scope.sendMail = function () {
            if (!$rootScope.online) {
                $scope.showOfflineMessage();
                return;
            }

            var promise = $scope.updateStorage(true, true, true);
            promise.then(function (greeting) {
                $timeout(function () {
                    SweetAlert.swal(
                        {
                            title: "Share via Email!",
                            text: 'Copy a link into your email client<br/><br/>' +
                                'Editable link: <a href="' + $scope.domain + '#/' + $scope.hash + '" target="_blank">' + $scope.domain + '#/' + $scope.hash + '</a><br/>' +
                                'Client link: <a href="' + $scope.domain + '#/' + $scope.hashReadOnly + '" target="_blank">' + $scope.domain + '#/' + $scope.hashReadOnly + '</a>',
                            type: "info",
                            showConfirmButton: true,
                            customClass: 'no-upper-case',
                            animation: "slide-from-top",
                            confirmButtonColor: "#4cb74c",
                            html: true
                        }, function (confirm) {

                        }
                    );
                }, 800);
            }, function (reason) {
                $scope.unknownError('Something went wrong', reason);
            });
        }

        $scope.sendQuoteRequest = function () {
            if (!$rootScope.online) {
                $scope.showOfflineMessage();
                return;
            }

            SweetAlert.swal(
                {
                    title: "Request a quote!",
                    text: 'Send this link<br/><a href="' + $scope.domain + '#/' + $scope.hash + '" target="_blank">' + $scope.domain + '#/' + $scope.hash + '</a><br/>to<br/><a href="mailto:info@squarevled.com">info@squarevled.com</a>',
                    type: "info",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    customClass: 'no-upper-case',
                    animation: "slide-from-top",
                    confirmButtonColor: "#4cb74c",
                    html: true
                }, function (confirm) {
                    if (confirm === false) return false;

                    var sm_your_name = jQuery('#sm_your_name').val();
                    var sm_your_email = jQuery('#sm_your_email').val();
                    var sm_your_phone = jQuery('#sm_your_phone').val();
                    var sm_dealer_name = jQuery('#sm_dealer_name').val();
                    var sm_timeframe = jQuery('#sm_timeframe').val();
                    var sm_city = jQuery('#sm_city').val();

                    if (sm_your_name === ""
                        || sm_your_email === ''
                        || sm_your_phone === ''
                        || sm_dealer_name === ''
                        || sm_timeframe === ''
                        || sm_city === '') {
                        $timeout(function () {
                            $scope.unknownError('Fill all fields', 'You need to fill all three inputs!');
                        }, 500);
                        return false
                    }

                    var promise = $scope.updateStorage(true);
                    promise.then(function (greeting) {
                        $http.post('/actions/quote/' + $scope.hash, {
                            your_email: sm_your_email,
                            your_name: sm_your_name,
                            your_phone: sm_your_phone,
                            dealer_name: sm_dealer_name,
                            timeframe: sm_timeframe,
                            city: sm_city
                        }).success(function (data, status, headers, config) {

                            Analytics.trackEvent('Share', 'Request quote', $scope.hash);
                            $window.Intercom('trackEvent', 'Request quote', {});

                            SweetAlert.swal(
                                {
                                    title: "Message sent",
                                    text: "Got it, thanks! We'll be in touch soon.",
                                    type: "success",
                                    confirmButtonColor: "#4cb74c"
                                });
                        }).error(function (data, status, headers, config) {

                            SweetAlert.swal(
                                {
                                    title: "Message sending error",
                                    text: "We couldn't send your message. Please try again!",
                                    type: "error",
                                    confirmButtonColor: "#4cb74c"
                                });
                        });
                    }, function (reason) {
                        $scope.unknownError('Something went wrong', reason);
                    });
                }
            );
        }

        $scope.removeScreen = function (screen) {
            SweetAlert.swal({
                    title: "Delete current wall?",
                    text: "",
                    type: "warning",
                    //html: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#4cb74c",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $scope.screens.splice(screen, 1);
                        Analytics.trackEvent('Screen', 'Remove screen', 'Screen no.', screen);
                        $window.Intercom('trackEvent', 'Remove screen', {
                            number_of_screens: $scope.screens.length
                        });
                    }
                });
        }

        $scope.resetEverything = function () {
            SweetAlert.swal({
                    title: "Reset wall(s)",
                    text: "Which wall(s) do you want to reset?<br/>" +
                        '<button class="cancel">Cancel</button>' +
                        '<button id="action-reset-this-wall">This wall</button>' +
                        '<button class="confirm" id="action-reset-all-walls">All walls</button>',
                    type: "warning",
                    html: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#4cb74c",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    customClass: 'hide-buttons'
                },
                function (isConfirm) {
                    if (isConfirm) {
                    }
                });
        }

        //Apples and oranges, but I don't have better idea how to solve this without changing SweetAlert source...
        $('body').on('click', '#action-reset-this-wall', function () {
            $scope.screens[$scope.currentScreen] = {};
            $scope.screens[$scope.currentScreen] = cloneObject(defaultScreenSettings);
            $scope.calculateScreenData($scope.currentScreen);
            $scope.checkMove();

            Analytics.trackEvent('Screen', 'Reset screen', 'Screen no.', $scope.currentScreen);
            $window.Intercom('trackEvent', 'Reset screen', {
                number_of_screens: $scope.screens.length
            });
        })

        $('body').on('click', '#action-reset-all-walls', function () {
            $scope.resetAllWalls();
            Analytics.trackEvent('Project', 'Reset project', 'All');
            $window.Intercom('trackEvent', 'Reset project', {});
        })

        $('body').on('click', '#resetToNewProject', function () {
            $scope.resetAllWalls();
            Analytics.trackEvent('Project', 'Reset project', 'All');
            $window.Intercom('trackEvent', 'Reset project', {});
        })

        $('body').on('click', '#needHelp', function () {
            $window.Intercom("show");
        })

        $scope.resetAllWalls = function () {
            $scope.ignoreUpdate = true;
            $scope.screens = [
                cloneObject(defaultScreenSettings)
            ];
            $scope.screensCalculated = [];
            $scope.calculateScreenData(0);
            $scope.currentScreen = 0;
            $scope.projectTitle = $scope.defaultProjectTitle;
            $rootScope.panelsOffsetActive = false;

            hash = "";
            $scope.hash = hash;
            $scope.syncId = null;
            $scope.hashReadOnly = "";
            manualLocationChange = true;
            $location.path('/');


        }

        $scope.defaultVideoUrl = 'https://www.youtube.com/watch?v=0pXYp72dwl0';

        $scope.lastVideoUrl = null;
        $scope.videoUrl = null;

        $rootScope.$watch('panelsOffsetActive', function (val) {
            if (val === true) {
                $scope.turnOffVideo();
            }
        })

        $scope.$watch('currentScreen', function (val) {
            if (typeof val === 'undefined') {
                $rootScope.panelsOffsetActive = true;
            } else {
                $scope.checkMove();
            }
        })

        $scope.checkMove = function () {
            $rootScope.panelsOffsetActive = false;
            if (typeof $scope.screens !== 'undefined' && $scope.currentScreen !== -1) {
                for (var i = 0; i < $scope.screens[$scope.currentScreen].panels.length; i++) {
                    if ($scope.screens[$scope.currentScreen].panels[i].xOffset !== 0) {
                        $rootScope.panelsOffsetActive = true;
                        break;
                    }

                    if ($scope.screens[$scope.currentScreen].panels[i].yOffset !== 0) {
                        $rootScope.panelsOffsetActive = true;
                        break;
                    }
                }
            }
        }

        $scope.playVideo = function () {
            if ($scope.videoUrl === null) {
                if ($scope.lastVideoUrl === null) {
                    if ($scope.generalSettings.videoUrl === undefined || $scope.generalSettings.videoUrl === '') {
                        $scope.videoUrl = $scope.defaultVideoUrl;
                    } else {
                        $scope.videoUrl = $scope.generalSettings.videoUrl;
                    }
                } else {
                    $scope.videoUrl = $scope.lastVideoUrl;
                }
            }
            if (!$scope.isIos() || $rootScope.videoState == 2) {
                if ($rootScope.videoProvider == 'youtube') {
                    playYoutubeVideo();
                }

                if ($rootScope.videoProvider == 'vimeo') {
                    playVimeoVideo();
                }
            }
        }

        $scope.pauseVideo = function () {
            if ($rootScope.videoProvider == 'youtube') {
                pauseYoutubeVideo();
            }

            if ($rootScope.videoProvider == 'vimeo') {
                pauseVimeoVideo();
            }
        }

        $scope.turnOffVideo = function () {
            $rootScope.videoState = -1;
            $scope.lastVideoUrl = $scope.videoUrl;
            $scope.videoUrl = null;
            videoPlayers = [];
            isPlayerReady = false;
        }

        $scope.toggleIosVideo = function () {
            if ($rootScope.videoState === undefined || $rootScope.videoState === -1) {
                $scope.playVideo();
                $rootScope.videoInit = true;
            } else {
                $rootScope.videoState = -1;
                $scope.lastVideoUrl = $scope.videoUrl;
                $scope.videoUrl = null;
                videoPlayers = [];
                isPlayerReady = false;
                $rootScope.videoInit = false;
            }
        }

        $rootScope.panelsOffsetActive = false;


        $scope.addVideo = function () {
            SweetAlert.swal(
                {
                    title: "Customize your content",
                    text: "Enter YouTube or Vimeo url" +
                        '<input type="text" id="video_url" placeholder="YouTube or Vimeo url" style="display: block"/>',
                    type: "info",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    animation: "slide-from-top",
                    confirmButtonColor: "#4cb74c",
                    html: true
                }, function (confirm) {
                    if (confirm === false) return false;

                    var video = jQuery('#video_url').val();


                    if (video === "") {
                        $timeout(function () {
                            $scope.unknownError('Enter url', 'Enter video url!');
                        }, 500);
                        return false
                    } else {
                        isPlayerReady = false;
                        $scope.videoUrl = video;
                        $scope.generalSettings.videoUrl = video;
                        $scope.playVideo();
                        Analytics.trackEvent('Project', 'Play video', video);
                        $window.Intercom('trackEvent', 'Play video', {
                            number_of_screens: $scope.screens.length
                        });
                    }
                }
            )


        }

        $scope.ignoreUpdate = false;

        $scope.defaultProjectTitle = 'Click to add Project title';
        $scope.projectTitle = $scope.defaultProjectTitle;
        $scope.lastTitle = $scope.projectTitle;
        $scope.editingTitle = false;
        $scope.editingTitle2 = false;


        $scope.editTitle = function (elem) {
            if ($scope.readOnly) {
                return;
            }
            $scope.editingTitle = true;
        }

        $scope.editTitle2 = function (elem) {
            if ($scope.readOnly) {
                return;
            }

            if ($scope.projectTitle === $scope.defaultProjectTitle) {
                $scope.projectTitle = '';
            }

            $scope.editingTitle2 = true;
        }

        $scope.saveTitleEdit = function () {
            $scope.editingTitle = false;
            $scope.editingTitle2 = false;

            if ($scope.projectTitle === '') {
                $scope.projectTitle = $scope.defaultProjectTitle;
            }

            if ($scope.projectTitle !== '' && $scope.lastTitel !== $scope.projectTitle) {
                Analytics.trackEvent('Project', 'Change project title', $scope.projectTitle);
                $window.Intercom('trackEvent', 'Change project title', {});
                $scope.updateStorage(true, true);
                $scope.lastTitel = $scope.projectTitle;
            } else if ($scope.projectTitle !== '') {
                $scope.projectTitle = $scope.lastTitel;
            }
        }

        $scope.editingScreenTitle = {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
        };

        $scope.screenTiles = {
            0: '',
            1: '',
            2: '',
            3: '',
            4: '',
        };

        $scope.editScreenTitle = function (elem) {
            if ($scope.readOnly) {
                return;
            }
            $scope.editingScreenTitle[$scope.currentScreen] = true;
        }

        $scope.saveScreenTitleEdit = function () {
            $scope.editingScreenTitle[$scope.currentScreen] = false;
            //if ($scope.screenTitle !== '' && $scope.lastScreenTitle !== $scope.screenTitle) {
            Analytics.trackEvent('Screen', 'Change screen title', $scope.screens[$scope.currentScreen].title);
            $window.Intercom('trackEvent', 'Change screen title', {});
            $scope.updateScreenTitle($scope.currentScreen, $scope.screens[$scope.currentScreen].title);
            //$scope.lastScreenTitle = $scope.screenTitle;
            //}
        }

        var calculatedData = {};

        $scope.$watch('generalSettings.voltage', function (newData, oldData) {
            if (oldData != undefined && newData !== oldData) {
                $scope.updateStorage(true, true);
            }
        });

        $scope.$watch('generalSettings.videoUrl', function (newData, oldData) {
            if (newData !== oldData && newData != $scope.defaultVideoUrl) {
                $scope.updateStorage(true, true);
            }
        });

        $scope.$watch('screens', function (newData, oldData) {
            if ($scope.ignoreUpdate) {
                $scope.ignoreUpdate = false;
                return;
            }

            if (newData == undefined) {
                return;
            }

            var screenMetricRounding = 1;
            var minSizeValue = 1;
            if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.metric) {
                screenMetricRounding = 2;
                minSizeValue = 0.5;
            }

            if (newData[$scope.currentScreen].vertical === null || newData[$scope.currentScreen].vertical === "" || newData[$scope.currentScreen].vertical < minSizeValue) {
                return;
            }

            if (newData[$scope.currentScreen].horizontal === null || newData[$scope.currentScreen].horizontal === "" || newData[$scope.currentScreen].horizontal < minSizeValue) {
                return;
            }

            if (calculatedData === JSON.stringify($scope.screens)) {
                return;
            }

            var screensTemp = $scope.screens;

            if (oldData !== undefined && oldData[$scope.currentScreen] !== undefined) {
                if ((newData[$scope.currentScreen].units !== oldData[$scope.currentScreen].units) && !loadedFromApi) {
                    var unitConversionFactor = 1;
                    if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.metric && oldData[$scope.currentScreen].units == $scope.screenOptions.units.imperial) {
                        unitConversionFactor = 1 / metricToImperialFactor;
                    } else if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.imperial && oldData[$scope.currentScreen].units == $scope.screenOptions.units.metric) {
                        unitConversionFactor = metricToImperialFactor;

                    } else if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.panels && oldData[$scope.currentScreen].units == $scope.screenOptions.units.imperial) {
                        unitConversionFactor = 1000 / $scope.settings.panel[$scope.screens[$scope.currentScreen].product.id].width * (1 / metricToImperialFactor);
                    } else if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.imperial && oldData[$scope.currentScreen].units == $scope.screenOptions.units.panels) {
                        unitConversionFactor = $scope.settings.panel[$scope.screens[$scope.currentScreen].product.id].width / 1000 * metricToImperialFactor;

                    } else if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.panels && oldData[$scope.currentScreen].units == $scope.screenOptions.units.metric) {
                        unitConversionFactor = 1000 / $scope.settings.panel[$scope.screens[$scope.currentScreen].product.id].width;
                    } else if ($scope.screens[$scope.currentScreen].units == $scope.screenOptions.units.metric && oldData[$scope.currentScreen].units == $scope.screenOptions.units.panels) {
                        unitConversionFactor = $scope.settings.panel[$scope.screens[$scope.currentScreen].product.id].width / 1000;
                    }

                    screensTemp[$scope.currentScreen].horizontal = $scope.screens[$scope.currentScreen].horizontal * unitConversionFactor;
                    screensTemp[$scope.currentScreen].vertical = $scope.screens[$scope.currentScreen].vertical * unitConversionFactor;
                }
            }

            if ((oldData !== undefined && oldData[$scope.currentScreen] !== undefined && $scope.screens[$scope.currentScreen].ratio !== $scope.screenOptions.ratio.custom) && !loadedFromApi) {
                if ((newData[$scope.currentScreen].horizontal !== oldData[$scope.currentScreen].horizontal) || (newData[$scope.currentScreen].ratio.name !== oldData[$scope.currentScreen].ratio.name)) {
                    screensTemp[$scope.currentScreen].vertical = newData[$scope.currentScreen].horizontal * ($scope.screens[$scope.currentScreen].ratio.y / $scope.screens[$scope.currentScreen].ratio.x);
                }
                if (newData[$scope.currentScreen].vertical !== oldData[$scope.currentScreen].vertical) {
                    screensTemp[$scope.currentScreen].horizontal = newData[$scope.currentScreen].vertical * ($scope.screens[$scope.currentScreen].ratio.x / $scope.screens[$scope.currentScreen].ratio.y);
                }
            }


            screensTemp[$scope.currentScreen].vertical = Math.round($scope.screens[$scope.currentScreen].vertical * screenMetricRounding) / screenMetricRounding;
            screensTemp[$scope.currentScreen].horizontal = Math.round($scope.screens[$scope.currentScreen].horizontal * screenMetricRounding) / screenMetricRounding;

            $scope.screens = screensTemp;

            $scope.calculateScreenData($scope.currentScreen);

            loadedFromApi = false;
            calculatedData = JSON.stringify(screensTemp);

            $scope.updateStorage();

            $scope.startTour = function () {
                hopscotch.startTour(tour);
            }

            if ($cookies.knownVisitor === undefined || !$cookies.knownVisitor) {
                if (!$scope.readOnly) {
                    $cookies.knownVisitor = true;
                    //$scope.startTour();
                }
            }
            ;

        }, true);

        $timeout(function () {
            $http.get('/sheet');
        }, 10000)

        $scope.showOfflineMessage = function () {
            SweetAlert.swal(
                {
                    title: "Offline mode",
                    text: "This function is disabled in offline mode!",
                    showConfirmButton: true,
                    type: 'error'
                });
        }

    }])
;

//Some more content to force update