angular.module('square-v')
    .filter('ceil', function () {
        return function (input) {
            return Math.ceil(input);
        };
    })
    .filter('floor', function () {
        return function (input) {
            return Math.floor(input);
        };
    });