angular.module('square-v')
    .directive('grid', ['$timeout', 'Analytics', '$rootScope', '$window', function ($timeout, Analytics, $rootScope, $window) {
        return {
            restrict: 'E',
            templateUrl: '/templates/grid.html',
            scope: {
                panels: '=',
                units: '=',
                product: '=',
                screenOptions: '=',
                screenCalculated: '=',
                screenIndex: '=',
                togglePanel: '=',
                scTitle: '=',
                updateScreenTitle: '=',
                selectScreen: '=',
                videoUrl: '=',
                readOnly: '=',
                currentScreen: '='
            },
            link: function (scope, elem, attrs) {
                scope.internalScreen = attrs.internalScreen;
                var grid = elem.find('.screen');
                var screenTitle = elem.find('.screen-title:first');

                var cols = scope.screenCalculated.horizontalPanels;
                var rows = scope.screenCalculated.verticalPanels;
                var maxFontSize = 300;

                attrs.$observe('allPanels', function () {
                    updateSizes(cols);
                });

                $(window).resize(function () {
                    updateSizes(cols);
                });

                scope.defaultScreenTitle = 'Screen ' + (scope.screenIndex + 1);

                scope.$watch('scTitle', function (val) {
                    if (scope.scTitle == undefined) {
                        scope.screenTitle = scope.defaultScreenTitle;
                    } else {
                        scope.screenTitle = val;
                    }
                })

                var checkIfPanelOffsetAvalaible = function (cols, rows) {
                    var lastCols, lastRows, ret;

                    if (cols && rows) {
                        lastCols = cols;
                        lastRows = rows;
                    }

                    if (scope.product.offset_capable === "No") {
                        scope.panelOffsetDisabled = true;
                        scope.resetMove();
                        return;
                    } else {
                        scope.panelOffsetDisabled = false;
                    }

                    if (lastCols && lastRows) {
                        if (lastCols <= 4 || lastRows <= 4) {
                            scope.panelOffsetDisabled = true;
                        } else {
                            scope.panelOffsetDisabled = false;
                        }
                    }
                    return;
                }

                scope.$watch('product', function (product) {
                    checkIfPanelOffsetAvalaible(null, null)
                })

                scope.lastTitle = scope.screenTitle;
                scope.editingScreenTitle = false;

                scope.editScreenTitle = function (elem) {
                    if (scope.readOnly) {
                        return;
                    }
                    scope.editingScreenTitle = true;
                }

                scope.lastMove = null;

                scope.resetMove = function () {
                    for (var i = 0; i < scope.panels.length; i++) {
                        scope.panels[i].xOffset = 0;
                        scope.panels[i].yOffset = 0;
                    }
                }

                scope.panelsOffsetActive = false;
                //$rootScope.panelsOffsetActive = false;
                scope.checkMove = function (internal) {
                    if (!internal) {
                        $rootScope.panelsOffsetActive = false;
                    }
                    scope.panelsOffsetActive = false;
                    for (var i = 0; i < scope.panels.length; i++) {
                        if (scope.panels[i].xOffset !== 0) {
                            if (!internal) {
                                $rootScope.panelsOffsetActive = true;
                            }
                            scope.panelsOffsetActive = true;
                            break;
                        }

                        if (scope.panels[i].yOffset !== 0) {
                            if (!internal) {
                                $rootScope.panelsOffsetActive = true;
                            }
                            scope.panelsOffsetActive = true;
                            break;
                        }
                    }
                }

                scope.checkMove(true);

                checkIfPanelOffsetAvalaible(null, null);

                scope.movePanel = function (row, x, y, $event) {
                    $event.stopPropagation();

                    if (x !== null) {
                        if (scope.lastMove === 'y') {
                            scope.resetMove();
                        }
                        for (var i = row * scope.screenCalculated.horizontalPanels; i < row * scope.screenCalculated.horizontalPanels + scope.screenCalculated.horizontalPanels; i++) {
                            if (scope.panels[i].xOffset + x >= -1 && scope.panels[i].xOffset + x <= 1) {
                                scope.panels[i].xOffset = scope.panels[i].xOffset + x;
                            }
                        }
                        scope.lastMove = 'x';
                    } else if (y !== null) {
                        if (scope.lastMove === 'x') {
                            scope.resetMove();
                        }
                        for (var i = row; i < scope.screenCalculated.horizontalPanels * scope.screenCalculated.verticalPanels; i = i + scope.screenCalculated.horizontalPanels) {
                            if (scope.panels[i].yOffset + y >= -1 && scope.panels[i].yOffset + y <= 1) {
                                scope.panels[i].yOffset = scope.panels[i].yOffset + y;
                            }
                        }
                        scope.lastMove = 'y';
                    }

                    scope.checkMove(false);
                }

                scope.saveScreenTitleEdit = function () {
                    scope.editingScreenTitle = false;
                    if (scope.screenTitle !== '' && scope.lastTitle !== scope.screenTitle) {
                        Analytics.trackEvent('Screen', 'Change screen title', scope.screenTitle);
                        $window.Intercom('trackEvent', 'Change screen title', {});
                        scope.updateScreenTitle(scope.screenIndex, scope.screenTitle);
                        scope.lastTitle = scope.screenTitle;
                    }
                }

                var createYtApi = function () {
                    if (isApiReady && $('#' + 'ytvideo' + scope.screenIndex).length > 0) {
                        var youtubePlayer = new YT.Player('ytvideo' + scope.screenIndex, {
                            events: {
                                'onReady': onPlayerReady,
                                'onStateChange': onPlayerStateChange
                            }
                        });
                        videoPlayers[scope.screenIndex] = youtubePlayer;
                    } else {
                        $timeout(createYtApi, 1000);
                    }
                }

                var createVimeoApi = function () {
                    if ($('#' + 'ytvideo' + scope.screenIndex).length > 0) {
                        var vimeoPlayer = $f(document.getElementById('ytvideo' + scope.screenIndex));

                        vimeoPlayer.addEvent('ready', onVimeoPlayerReady);
                        vimeoPlayer.addEvent('play', onVimeoPlayerPlay);
                        vimeoPlayer.addEvent('pause', onVimeoPlayerPause);
                        vimeoPlayer.addEvent('finish', onVimeoPlayerFinish);
                        vimeoPlayer.addEvent('playProgress', onVimeoPlayerPlayProgress);


                        videoPlayers[scope.screenIndex] = vimeoPlayer;
                    } else {
                        $timeout(createVimeoApi, 1000);
                    }
                }

                scope.videoSelected = function (videoId, provider) {
                    if (scope.videoUrl !== null) {
                        isPlayerReady = false;

                        $rootScope.videoProvider = provider;

                        if (provider == 'youtube') {
                            createYtApi();
                        }

                        if (provider == 'vimeo') {
                            createVimeoApi();
                        }
                    }
                }

                var updateSizes = function (cols) {
                    cols = scope.screenCalculated.horizontalPanels;
                    rows = scope.screenCalculated.verticalPanels;

                    checkIfPanelOffsetAvalaible(cols, rows)

                    if (cols > 0) {
                        var containerHeight = angular.element('body').innerHeight() - angular.element('#bottomRow').innerHeight() - 250;
                        var productRatio = 1;
                        productRatio = scope.product.pixels_height / scope.product.pixels_width;

                        if (productRatio > 1) {
                            containerHeight = containerHeight / productRatio;
                        }

                        var elementWidth = 100 / cols;
                        var elementHeight = elementWidth * productRatio;

                        //$timeout(function () {
                        var panels = elem.find('.screen-panel');
                        //panels.css('width', elementWidth + '%');
                        //panels.css('padding-bottom', elementWidth + '%');

                        $('#dynamicStyle' + scope.screenIndex).remove();
                        $('head').append('<style type="text/css" id="dynamicStyle' + scope.screenIndex + '">.screen-num-' + scope.screenIndex + ' .screen-panel {width: ' + elementWidth + '% !important; padding-bottom: ' + elementHeight + '% !important;}</style>');

                        $timeout(function () {

                            var containerWidth = $(elem).outerWidth() * 0.6;
                            var calculatedElementWidth = containerWidth * (elementWidth / 100);

                            var maxHeight = containerHeight / rows;

                            var panel = elem.find('.screen-panel').first();

                            var width = panel.outerWidth();
                            var height = width * productRatio;

                            var mql = window.matchMedia("screen and (min-width: 992px)")
                            if (calculatedElementWidth > maxHeight && mql.matches) {
                                width = maxHeight;
                                height = maxHeight * productRatio;
                                //							panels.css('width', height);
                                grid.css('width', width * cols);
                                //$('#dynamicStyle' + scope.screenIndex).remove();
                                $('head').append('<style type="text/css" id="dynamicStyle' + scope.screenIndex + '">.screen-num-' + scope.screenIndex + ' .screen-panel {width: ' + width + 'px !important; padding-bottom: ' + height + 'px !important;} .screen-num-' + scope.screenIndex + ' .row-offset-right{left: ' + (50 / cols) + '%} .screen-num-' + scope.screenIndex + ' .row-offset-left{left: -' + (50 / cols) + '%} .screen-num-' + scope.screenIndex + ' .row-offset-top{top: -' + (height / 2) + 'px} .screen-num-' + scope.screenIndex + ' .row-offset-bottom{top: ' + (height / 2) + 'px}</style>');
                            } else {
                                grid.css('width', 'calc(60% - 10px)');
                                $('head').append('<style type="text/css" id="dynamicStyle' + scope.screenIndex + '">.screen-num-' + scope.screenIndex + ' .screen-panel {width: ' + elementWidth + '% !important; padding-bottom: ' + elementHeight + '% !important;} .screen-num-' + scope.screenIndex + ' .row-offset-right{left: ' + (50 / cols) + '%} .screen-num-' + scope.screenIndex + ' .row-offset-left{left: -' + (50 / cols) + '%} .screen-num-' + scope.screenIndex + ' .row-offset-top{top: -' + (height / 2) + 'px} .screen-num-' + scope.screenIndex + ' .row-offset-bottom{top: ' + (height / 2) + 'px}</style>');
                            }

                            if ((height * cols) / (height * rows) < (16 / 9)) {
                                elem.find('.embed-video-wrapper').first().addClass('vertical');
                            } else {
                                elem.find('.embed-video-wrapper').first().removeClass('vertical');
                            }

                            var silhouetteSize = (90 * 4) / rows * (500 / scope.product.height); // 90 * 4 Equals to ~6ft (all panels are 500mm)
                            elem.find('.silhouette').first().css('height', silhouetteSize + '%');

                            //Change font-size
                            $timeout(function () {
                                var containerSize = Math.round(screenTitle.innerHeight());
                                var fontSize = Math.round(containerSize * 0.7);
                                if (fontSize > maxFontSize) {
                                    fontSize = maxFontSize;
                                }
                                screenTitle.css('font-size', fontSize + 'px');
                                screenTitle.css('line-height', containerSize + 'px');
                            }, 300);
                        }, 50);//
                        //}, 300);
                    }
                };

            }
        };
    }]);